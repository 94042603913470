// import React, { useState } from "react";
import React from "react";
import Amplify from 'aws-amplify';
import Analytics from '@aws-amplify/analytics';
// import ReactGA from 'react-ga';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import withTracker from './withTracker';
import styled from "styled-components";

import ScrollToTop from "./ScrollToTop";
import TopBar from "./components/TopBar";
import Footer from "./components/Footer";

// links
import {
  whatIsDementia,
  maoriUnderstanding,
  typesSymptonsCauses,
  types,
  causes,
  symptoms,
  whatCanIDo,
  affectedWhanau,
  whatsWrong,
  resources,
  acknowledgements,
} from "./utils/links";

// pages
import Splash from "./pages/Splash";
import Home from "./pages/Home";
import WhatIsDementia from "./pages/WhatIsDementia";
import MaoriUnderstanding from "./pages/MaoriUnderstanding";
import TypesSymptonsCauses from "./pages/TypesSymptonsCauses";
import Types from "./pages/Types";
import Symptoms from "./pages/Symptoms";
import Causes from "./pages/Causes";

import WhatCanIDo from "./pages/WhatCanIDo";
import AffectedWhanau from "./pages/AffectedWhanau";
import WhatsWrong from "./pages/WhatsWrong";
import Resources from "./pages/Resources";
import Acknowledgements from "./pages/Acknowledgements";

import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

Analytics.autoTrack('pageView', {
  enable: true,
  type: 'SPA'
});

// const trackingId = "UA-19406212-4"; // Replace with your Google Analytics tracking ID
// ReactGA.initialize(trackingId);
// ReactGA.pageview(window.location.pathname + window.location.search);


const AppContainer = styled.div`
  margin-top: 64px;
`;

const Routes = () => {

  return (
    <div>
      <Router >
        <TopBar />
        <ScrollToTop />
        <Switch>
          <AppContainer>

            <Route exact path="/">
              <Splash />
            </Route>

            <Route path="/home">
              <Home />
            </Route>

            <Route path={whatIsDementia} component={withTracker(WhatIsDementia)} />
            <Route path={maoriUnderstanding} component={withTracker(MaoriUnderstanding)} />

            {/* <Route path={maoriUnderstanding}>
              <MaoriUnderstanding />
            </Route> */}

            <Route path={typesSymptonsCauses} component={withTracker(TypesSymptonsCauses)} />
            {/* <Route path={typesSymptonsCauses}>
              <TypesSymptonsCauses />
            </Route> */}

            <Route path={types} component={withTracker(Types)} />
            {/* <Route path={types}>
              <Types />
            </Route> */}

            <Route path={symptoms} component={withTracker(Symptoms)} />
            {/* <Route path={symptoms}>
              <Symptoms />
            </Route> */}

            <Route path={causes} component={withTracker(Causes)} />
            {/* <Route path={causes}>
              <Causes />
            </Route> */}

            <Route path={whatCanIDo} component={withTracker(WhatCanIDo)} />
            {/* <Route path={whatCanIDo}>
              <WhatCanIDo />
            </Route> */}

            <Route path={affectedWhanau} component={withTracker(AffectedWhanau)} />
            {/* <Route path={affectedWhanau}>
              <AffectedWhanau />
            </Route> */}

            <Route path={whatsWrong} component={withTracker(WhatsWrong)} />
            {/* <Route path={whatsWrong}>
              <WhatsWrong />
            </Route> */}

            <Route path={resources} component={withTracker(Resources)} />
            {/* <Route path={resources}>
              <Resources />
            </Route> */}

            <Route path={acknowledgements} component={withTracker(Acknowledgements)} />
            {/* <Route path={acknowledgements}>
              <Acknowledgements />
            </Route> */}
          </AppContainer>
        </Switch>

        <Footer />
      </Router>
    </div>
  );
};

export default Routes;
