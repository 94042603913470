import React, { useState } from "react";
import styled from "styled-components";
// import { useSpring, animated } from "react-spring";
import { fontFamily, fontNormal, fontBold, fontRegular } from "../styles/fonts";
import { firstColor } from "../styles/colors";

const AccordingContainer = styled.div`
  margin-top: -2px;
  border-top: 1.5px solid #000000;
  border-bottom: ${(props) => props.borderBottom || "none"};
  padding: 2px 20px;

  @media only screen and (min-width: 768px) {
    margin-top: -2px;
  }
`;

const TopFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`;

const Text = styled.p`
  color: ${(props) => props.fontColor || "#1C1C1C"};
  font-family: ${fontFamily};
  font-size: 20px;
  font-style: ${fontNormal};
  font-weight: ${(props) => props.fontWeight || fontRegular};
  line-height: 1;

  &:hover {
    color: ${firstColor};
  }
`;

const ArrowBt = styled.img`
  cursor: pointer;
`;

const Content = styled.div`
  margin-bottom: 40px;
`;

const According = ({ title, content, borderBottom }) => {
  const [showContent, setShowContent] = useState(false);
  // const scale = useSpring({ scale: 1, from: { scale: 0 } });

  return (
    <AccordingContainer borderBottom={borderBottom}>
      <TopFlex onClick={() => setShowContent(!showContent)}>
        <Text
          fontWeight={fontBold}
          fontColor={showContent === false ? "#1C1C1C" : firstColor}
        >
          {title}
        </Text>
        {showContent === false ? (
          <ArrowBt src="images/arrow-down.svg" alt="Arrow down" />
        ) : (
            <ArrowBt src="images/arrow-up.svg" alt="Arrow up" />
          )}
      </TopFlex>
      {showContent === true && <Content>{content}</Content>}
    </AccordingContainer>
  );
};

export default According;
