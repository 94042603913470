import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    align-self: flex-start;
    flex-direction: row;
  }
`;

const IconContainer = styled.div`
  text-align: center;

`;

const Icon = styled.img`
  margin: 60px 40px 0 0;
  width: 80px;

  @media only screen and (min-width: 768px) {
    width: 60px;
  }
`;

const CardHorizontal = ({ icon, title, content }) => {
  return (
    <CardContainer>
      <IconContainer>
        <Icon src={icon} alt={title} />
      </IconContainer>
      <div>
        <h2>{title}</h2>
        {content}
      </div>
    </CardContainer>
  );
};

export default CardHorizontal;
