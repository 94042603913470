export const whatIsDementia = "/what-is-dementia";
export const maoriUnderstanding = "/maori-understanding";
export const typesSymptonsCauses = "/types-symptoms-causes";
export const types = "/types";
export const symptoms = "/symptoms";
export const causes = "/causes";

export const whatCanIDo = "/what-can-i-do";
export const affectedWhanau = "/affected-whanau";
export const whatsWrong = "/whats-wrong";

export const resources = "/resources";
export const acknowledgements = "/acknowledgements";
