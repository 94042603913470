import React from "react";
import styled from "styled-components";

const BulletContainer = styled.div`
  display: flex;
  margin: 10px 0 10px 20px;
`;

const BulletImage = styled.div`
  margin-right: 10px;
`;

const BulletText = styled.div`
  font-family: "questa-sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
`;

const Bullet = ({ text }) => {
  return (
    <BulletContainer>
      <BulletImage>
        <img src="images/bullet.svg" alt="Arrow bullet" />
      </BulletImage>
      <BulletText>{text}</BulletText>
    </BulletContainer>
  );
};

export default Bullet;
