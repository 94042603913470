import styled from "styled-components";
import { linkColor } from "../styles/colors";
import { fontFamily, fontNormal, fontBold } from "../styles/fonts";

export const ContentContainer = styled.div`
  margin: 50px 20px;

  @media only screen and (min-width: 768px) {
    margin: 55px auto;
    max-width: 80%;
  }

  @media only screen and (min-width: 996px) {
    margin: 55px auto;
    max-width: 60%;
  }
`;

export const CardsImagesFlex = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const CardsFlex = styled.div`
  align-items: center;
  margin: 50px 20px;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    margin: 80px auto;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 90%;
  }

  @media only screen and (min-width: 998px) {
    width: 1000px;
  }
`;

export const TopTitle = styled.div`
  margin: 100px 0;
  min-height: 60vh;
`;

export const AccordingContainer = styled.div`
  margin: 50px 0;
`;

export const ImageFull = styled.img`
  margin-top: 40px;
  width: 100%;
`;

export const SimpleLink = styled.p`
  color: ${linkColor};
  font-family: ${fontFamily};
  font-size: 20px;
  font-style: ${fontNormal};
  font-weight: ${fontBold};
  text-decoration: underline;
`;

// export const Video = styled.video`
//   display: inherit;
//   object-fit: cover;
//   left: 0;
//   height: ${(props) => props.inputHeight || "90vh"};
//   position: absolute;
//   top: 0;
//   width: 100%;
//   z-index: -100;
// `;
