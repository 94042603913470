import React from "react";
import Header from "../components/Header";
import CardHorizontal from "../components/CardHorizontal";

import { ContentContainer } from "../styles/general";

import CallOut, {
  CallOutExternalLink,
  CallOutText,
} from "../components/CallOut";

const contentOne = (
  <p>
    This will help you understand why your kuia or kaumātua is acting
    differently. Kōrero to your friends so they too can learn about mate
    wareware. It may affect someone in their whānau.
  </p>
);

const contentTwo = (
  <p>
    Ask if you can help to look after your nan or koro. Just sitting with them
    or playing card games or memory games with them or singing to them may be
    helpful.
  </p>
);

const contentThree = (
  <p>
    Talk to your kuia or kaumātua about the past. Ask them to talk about their
    life; What were the highlights of their life? What were the happy times?
    What achievements are they proud of? Ask them to recite their whakapapa.
    Ask them to say karakia. These are all activities which your kuia or
    kaumātua will enjoy doing and may also help them to remember other things.
    These are taonga for you to share.
  </p>
);

const contentFour = (
  <p>
    Sometimes your kuia or kaumātua might behave in ways that are strange, and
    you might feel embarrassed, frightened or angry. Try and be understanding.
    Kōrero to your friends about mate wareware. Tell them how your kuia or
    kaumātua used to be before they had mate wareware. Tell them how mate
    wareware has changed your kuia or kaumātua. Speak on behalf of your kuia
    and kaumātua. They would be proud of you.
  </p>
);

const contentFive = (
  <p>
    Help your kuia or kaumātua with technology even if it is only to show them
    how to turn on the TV and/or change channels. They may want to know how to
    use a mobile phone. If you have an iPad you might want to show them its
    different uses.
  </p>
);

const contentSix = (
  <p>
    It is valuable to find things for your kuia or kaumātua to do that will
    help you or your whānau and give them a better feeling of being useful.
    Walking the dog, watering the garden, taking out the rubbish are some
    useful activities that you can do alongside your kuia or kaumātua.
  </p>
);

const callOutContent = (
  <div>
    <CallOutText>
      For a booklet about dementia for children and young people,{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.ambulance.wales.nhs.uk/assets/documents/9d808b0d-8087-4fef-9539-0bfbc80685b5636598253101619982.pdf"
      >
        <CallOutExternalLink>
          download this PDF by Mental Health Foundation
        </CallOutExternalLink>{" "}
        <img src="images/external_link.svg" alt="External link" />
      </a>
    </CallOutText>
  </div>
);

const WhatsWrong = () => {
  return (
    <div>
      <Header
        inputHeight="60vh"
        image="/images/photos/whats-wrong-kuia.jpg"
        link="/"
        media="image"
      />
      <ContentContainer>
        <h1>What’s wrong with our Kuia/Kaumātua? Advice for rangatahi</h1>
        <p>
          Your kuia or kaumātua may be experiencing difficulties with their
          memory which is affecting their behaviour. They might be suffering
          from mate wareware sometimes known as dementia. This is a disease of
          the brain which can be caused by different illnesses. You might have
          noticed that the memory abilities of your kuia/kaumātua are getting
          worse, they are more confused and can’t do things as well as they used
          to such as showering themselves, getting dressed or preparing kai. You
          might also have noticed changes in their personality. These changes
          may be due to the mate wareware.{" "}
        </p>

        {/* <video controls width="100%" poster="/images/video-poster-dr-waiora-port.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/dr_waiora_port-small.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video> */}

        <h2>How can you help?</h2>
        <p>
          Your help, no matter how small, will help share the task of taking
          care of your kuia or kaumātua which will benefit the whole whānau.
        </p>
        <p>Be yourself. Be the best mokopuna you can be. What you can do:</p>

        <CardHorizontal
          icon="images/wrong-1.svg"
          title="Learn about mate wareware"
          content={contentOne}
        />

        <CardHorizontal
          icon="images/wrong-2.svg"
          title="Spend time with them"
          content={contentTwo}
        />


        <CardHorizontal
          icon="images/wrong-3.svg"
          title="Talk about the past"
          content={contentThree}
        />
        <CardHorizontal
          icon="images/wrong-4.svg"
          title="Try and be understanding"
          content={contentFour}
        />
        <CardHorizontal
          icon="images/wrong-5.svg"
          title="Help them with technology"
          content={contentFive}
        />
        <CardHorizontal
          icon="images/wrong-6.svg"
          title="Do activities with them"
          content={contentSix}
        />
        <CallOut callOutContent={callOutContent} />
      </ContentContainer>
    </div>
  );
};

export default WhatsWrong;
