import React from "react";
import { Link } from 'react-router-dom'
import Header from "../components/Header";
import Bullet from "../components/Bullet";
import According from "../components/According";
import CallOut, {
  CallOutExternalLink,
  CallOutText,
} from "../components/CallOut";

import CardImage from "../components/CardImage";

import {
  ContentContainer,
  CardsImagesFlex,
  AccordingContainer,
  SimpleLink
} from "../styles/general";

// links
import { causes, affectedWhanau } from "../utils/links";

const contentLifestyle = (
  <div>
    <p>
      Research suggests that how we live our lives may impact our risk of mate
      wareware. We know that smoking and excessive alcohol are not good for the
      body and research suggests they are also not good for the brain. So:
    </p>
    <p>
      <strong>Reduce</strong> tobacco smoking <br /> <strong>Reduce</strong>{" "}
      consuming too much alcohol
    </p>
    <p>
      Similarly, we know that what we eat and how active we are may also affect
      our health. This is partly due to the risk of developing heart diseases,
      caused by poor diet and limited exercise which may result in more stress
      on the body. Kai i te kai pai; hua rākau, hua whenua, kai moana.
    </p>
    <p>
      <strong>Exercise</strong> 30 minutes a day
    </p>
  </div>
);

const contentWhanaungatanga = (
  <div>
    <Bullet text="Keep your mind busy – do lots. " />
    <Bullet text="Spend time with whānau and friends." />
    <Bullet text="Spend time on the marae" />
    <Bullet text="Kōrero is good whether it be reciting whakapapa or karakia, singing waiata or just talking with others. All these activities stimulate your brain." />
    <Bullet text="Other activities that can help keep your brain healthy are; raranga, waka ama, whakairo, kapa haka, mau rākau, walking, tramping, gardening, tai chi, yoga, swimming, bowls, playing cards, painting, reading, fishing, hunting, and computer work. Any activity which requires you to think (and most do!), contribute to brain health." />
    <p>Start now!</p>
  </div>
);

const contentManaakitanga = (
  <p>
    Help each other out. Doing things together is more fun and it is easier to
    keep motivated. Join kaumātua groups. Enjoy being in the company of others.
  </p>
);

const callOutContentACP = (
  <div>
    <CallOutText>
      To obtain a copy of an Advanced Care Plan form please visit:
    </CallOutText>
    <a
      target="_blank"
      href="https://www.hqsc.govt.nz/assets/ACP/PR/ACP_Plan_forms_.pdf"
      rel="noopener noreferrer"
    >
      <CallOutExternalLink>
        Advanced Care Plan form
      </CallOutExternalLink>{" "}
      <img src="images/external_link.svg" alt="External link" />
    </a>

  </div>
);

const callOutContent = (
  <div>
    <CallOutText>
      Learn more about mate wareware prevention and management:
    </CallOutText>
    <a
      target="_blank"
      href="https://yourbrainmatters.org.au/"
      rel="noopener noreferrer"
    >
      <CallOutExternalLink>
        Your Brain Matters: The power of prevention
      </CallOutExternalLink>{" "}
      <img src="images/external_link.svg" alt="External link" />
    </a>
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.alzheimers.org.nz/information-and-support/support/living-well-with-dementia"
      >
        <CallOutExternalLink>Living well with dementia</CallOutExternalLink>{" "}
        <img src="images/external_link.svg" alt="External link" />
      </a>
    </div>
  </div>
);

const WhatCanIDo = () => {
  return (
    <div>
      <Header
        inputHeight="60vh"
        image="/images/photos/if-concerned.jpg"
        link="/"
        media="image"
      />
      <ContentContainer>
        <h1>If I’m concerned, what can I do?</h1>

        <video controls width="100%" poster="/images/video-poster-bobby01.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/bobby01.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video>

        <h2>Who should I see if I am worried about mate wareware?</h2>
        <p>
          If you think you or someone you care about may have mate wareware,
          it is important to see your doctor (or health provider) for an
          assessment.
        </p>
        <p>How to prepare for a visit to the doctor.</p>
        <Bullet text="Make a list of examples of behaviours that you are worried about" />
        <Bullet text="Make a note of all medications that are currently being taken." />
        <Bullet text="Make a list of things such as diet, exercise, friends, hobbies that you might want to kōrero about with the doctor." />
        <Bullet text="knowing what is going on" />
        <Bullet text="having the opportunity to learn more about mate wareware" />
        <Bullet text="access to services and support" />
        <Bullet text="starting to plan for the future" />

        <p>
          For some people, medication may be available which might delay further
          progression of symptoms. An assessment might also identify other
          causes for memory problems that can be treated (having memory problems
          does NOT necessarily mean you have mate wareware).
        </p>

        <p>
          When you see your doctor they will likely ask you a number of
          questions, and complete a number of tests including a physical
          examination, blood tests, and tests of your memory and thinking
          ability. They might also ask to speak to your whānau and ask for you
          to have a brain scan. Depending on the outcome of those tests, your
          doctor may send you for further tests and investigations. In some
          cases, this will involve seeing a person who specialises in memory
          problems.
        </p>

        <p>
          If there is a diagnosis of mate wareware you or your whānau may want
          to hold a whānau hui. Enduring Power of Attorney to choose someone to look after your wellbeing,
          finance and property, a whānau roster, or perhaps any safety concerns may be some issues to discuss.
        </p>

        <h2>Advance Care Plan (ACP)</h2>

        <p>An ACP provides an opportunity for you the whānau member to make a record of what is important to you
        as well as any health care plans you may want. You, members of your whānau and your healthcare team can
        work together to make decisions about your care in the event there comes a time when you can no longer
          tell them what you want. You should review you ACP every year.</p>

        <CallOut callOutContent={callOutContentACP} />

        <h2>Tino Rangatiratanga: What you can do to help</h2>
        <Link to={causes}>
          <SimpleLink>What causes mate wareware?</SimpleLink>
        </Link>

        <p>
          There are several life-style behaviours that can help in the fight
          against mate wareware.
        </p>

        <p>
          There are lots of reasons why we get mate wareware. Even if you do the
          right thing you still might get mate wareware but it is better to try
          and it is never too late to start!
        </p>

        <AccordingContainer>
          <According title="Lifestyle factors" content={contentLifestyle} />
          <According
            title="Whanaungatanga/Socialisation"
            content={contentWhanaungatanga}
          />
          <According
            title="Manākitanga"
            content={contentManaakitanga}
            borderBottom="1.5px solid #000000"
          />
        </AccordingContainer>
        <p>
          Have a check-up with your doctor. A doctor needs to monitor some
          things for you regularly like your weight, blood pressure, and sugar
          levels. By keeping these things at a healthy level, you may reduce the
          risk of getting mate wareware. Remember you need to eat healthily,
          exercise, and reduce stress in your life to keep your body healthy.
        </p>
        <CallOut callOutContent={callOutContent} />
        <h2>Related content</h2>
        <CardsImagesFlex>
          <CardImage
            title="What causes mate wareware?"
            text="It is not actually known what causes mate wareware but there are many conditions that are connected to changes..."
            image="/images/photos/types-symptons-causes.jpg"
            link={causes}
          />
          <CardImage
            title="Kaitiakitanga: looking after affected whānau"
            text="Listen carefully and know the person’s past. Kōrero with them about their stories."
            image="/images/photos/affected-whanau.jpg"
            link={affectedWhanau}
          />
        </CardsImagesFlex>
      </ContentContainer>
    </div>
  );
};

export default WhatCanIDo;
