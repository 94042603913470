import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { fontFamily, fontNormal, fontBlack } from "../styles/fonts";

const CardContainer = styled.div`
  margin: 20px 0;
  text-align: center;
  width: 280px;
  @media only screen and (min-width: 768px) {
    margin: 20px;
  }
`;

const Icon = styled.img`
  @media only screen and (min-width: 768px) {
  }
`;

const Text = styled.p`
  font-family: ${fontFamily};
  font-size: 26px;
  font-style: ${fontNormal};
  font-weight: ${fontBlack};
  line-height: 1;

  @media only screen and (min-width: 768px) {
  }
`;

const Card = ({ text, icon, link, alt }) => {
  return (
    <CardContainer>
      <Link to={link}>
        <Icon src={icon} alt={alt} />
        <Text>{text}</Text>
      </Link>
    </CardContainer>
  );
};

export default Card;
