import React from "react";
import styled from "styled-components";

const FooterContainer = styled.div`
  background-image: url("/images/pattern.svg");
  background-repeat: repeat-x;
  background-color: #c8c9c7;
  color: white;
  height: 800px;

  @media only screen and (min-width: 768px) {
    height: 300px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 150px;

  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const LogoBrain = styled.img`
  margin-bottom: 35px;
  height: 49px;
  margin-right: 34px;
  width: 127px;
`;

const LogoAcklandUni = styled.img`
  margin-bottom: 35px;
  height: 30px;
  width: 93px;
  margin-right: 34px;
`;

const LogoAUT = styled.img`
margin-bottom: 35px;
height: 30px;
width: 42px;
margin-right: 34px;
`;

const LogoMedTech = styled.img`
margin-bottom: 35px;
margin-right: 34px;
height: 47px;
width: 155px;
`;

const LogoAlzheimer = styled.img`
margin-bottom: 35px;
margin-right: 34px;
height: 43px;
width: 43px;
`;

const LogoDementia = styled.img`
margin-bottom: 35px;
margin-right: 34px;
height: 44px;
width: 96px;
`;

const LogoCallaghan = styled.img`
margin-bottom: 35px;
margin-right: 34px;
height: 29px;
width: 130px;
`;

const LogoVictoria = styled.img`
margin-bottom: 35px;
margin-right: 34px;
height: 29px;
width: 30px;
`;

const Footer = () => {
  return (
    <FooterContainer>

      <LogoContainer>
        <a href="http://www.brnz.ac.nz/" target="blank">
          <LogoBrain src="/images/footer-logo-brain-research@2x.png" />
        </a>
        <a href="https://www.cmdt.org.nz/" target="blank">
          <LogoMedTech src="/images/footer-logo-medtech@2x.png" />
        </a>
        <a href="https://www.alzheimers.org.nz/" target="blank">
          <LogoAlzheimer src="/images/footer-logo-alzheimer@2x.png" />
        </a>

        <a href="https://dementia.nz/" target="blank">
          <LogoDementia src="/images/footer-logo-dementia-nz@2x.png" />
        </a>

        <a href="https://www.auckland.ac.nz/" target="blank">
          <LogoAcklandUni src="/images/footer-logo-auckland-uni@2x.png" />
        </a>
        <a href="https://www.aut.ac.nz/" target="blank">
          <LogoAUT src="/images/footer-logo-aut.png" />
        </a>

        <a href="https://www.callaghaninnovation.govt.nz/" target="blank">
          <LogoCallaghan src="/images/footer-logo-callaghan-innovation@2x.png" />
        </a>

        <a href="https://www.wgtn.ac.nz/" target="blank">
          <LogoVictoria src="/images/footer-logo-victoria.png" />
        </a>

      </LogoContainer>

    </FooterContainer>
  );
};

export default Footer;
