import React from "react";
import Header from "../components/Header";
import Quote from "../components/Quote";
import CallOut, {
  CallOutExternalLink,
  CallOutText,
} from "../components/CallOut";
import CardImage from "../components/CardImage";

import { ContentContainer, CardsImagesFlex } from "../styles/general";

// links
import { whatCanIDo, affectedWhanau } from "../utils/links";

const callOutContent = (
  <div>
    <CallOutText>
      If you want to know more about the symptoms of mate wareware,{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.alzheimers.org.nz/"
      >
        <CallOutExternalLink>
          visit the Alzheimer’s NZ website
        </CallOutExternalLink>{" "}
        <img src="images/external_link.svg" alt="External link" />
      </a>
    </CallOutText>
  </div>
);

const MaoriUnderstanding = () => {
  return (
    <div>
      <Header
        inputHeight="60vh"
        image="/images/photos/understanding.jpg"
        link="/"
        media="image"
      />
      <ContentContainer>
        <h1>Māori understanding of mate wareware</h1>
        <video controls width="100%" poster="/images/video-poster-ngawai01.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/ngawai01.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video>
        <p>
          Some Māori hold a cultural understanding of memory loss, forgetfulness
          and confusion related to ageing.
        </p>
        <p>
          In Te Ao Māori, mate wareware may not be considered to be an illness
          or a disease but rather as part of a spiritual journey and as a normal
          consequence of growing old and preparation for joining tūpuna. These
          understandings may be very different from those held by doctors,
          nurses and support workers.
        </p>
        <h2>
          A cultural understanding of mate wareware is less likely to be framed
          in a negative way.
        </h2>
        <p>Some comments from kaumātua about mate wareware:</p>
        <Quote text="This is a gift to the Māori - forgetfulness." />
        <Quote
          text="You come into this world as a baby, 
        you go back to that world as a baby."
        />
        <Quote
          text="I remember my aunties and uncles 
        saying they were talking to the old people. 
        They are with them. They  are over on the other side. 
        They are between two worlds. So, don’t worry about them. 
        They are okay."
        />
        <h2>2016 – 2018 Rangahau/Research into mate wareware</h2>
        <p>
          Two hundred and twenty-three kaumātua from across Aotearoa, as well as
          eight whānau from the Waikato rohe were recruited using
          whanaungatanga. The kaumātua participated in 17 focus group interviews
          across seven study locations (Kaitaia, Tāmaki Makaurau, Kirikiriroa,
          Taranaki, Whakatāne, Whanganui-a-Tara and Ōtautahi). All major iwi
          were represented. Kaumātua were given the option to be part of a focus
          group in either te reo Māori or te reo Pākehā. They were asked to
          kōrero about their understanding and experiences of mate wareware.
        </p>
        <p>
          One aim of the rangahau was to use the kōrero of the kaumātua to
          develop a kaupapa Māori theory of dementia.
        </p>

        <p>
          One article has been published in the New Zealand Medical Journal
          (Dudley, M., Menzies, O., Elder, H., Nathan, L., Garrett, N., &
          Wilson, D. (2019). Mate wareware: Understanding'dementia'from a Māori
          perspective. The New Zealand Medical Journal (Online), 132(1503),
          66-74. <a href="https://www.nzma.org.nz/journal-articles/mate-wareware-understanding-dementia-from-a-maori-perspective" target="blank">
            <CallOutExternalLink>Access the article {" "}</CallOutExternalLink>
            <img src="images/external_link.svg" alt="External link" />
          </a>
        </p>
        <p>
          {" "}
          Another article from the researchers is about to be submitted for
          publication.
        </p>
        <p>
          A second aim of the research was to use the kōrero from the kaumātua
          to develop a tool that will assist in detecting mate wareware in
          Māori. As a result of this mahi, the Māori Assessment of
          Neuropsychological Abilities (MANA) tool is currently being developed.
        </p>
        <CallOut callOutContent={callOutContent} />
        <h2>Related content</h2>
        <CardsImagesFlex>
          <CardImage
            title="If I’m concerned, what can I do?"
            text="There are several life-style behaviours that can help in the fight against mate wareware."
            image="/images/photos/if-concerned.jpg"
            link={whatCanIDo}
          />
          <CardImage
            title="Kaitiakitanga: looking after affected whānau"
            text="Listen carefully and know the person’s past. Kōrero with them about their stories."
            image="/images/photos/affected-whanau.jpg"
            link={affectedWhanau}
          />
        </CardsImagesFlex>
      </ContentContainer>
    </div>
  );
};

export default MaoriUnderstanding;
