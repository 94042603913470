import React from "react";
import Header from "../components/Header";
import CallOut, {
  CallOutText,
  CallOutExternalLink,
} from "../components/CallOut";
import Bullet from "../components/Bullet";
import CardImage from "../components/CardImage";

import {
  ContentContainer,
  CardsImagesFlex,
} from "../styles/general";

// links
import { typesSymptonsCauses, affectedWhanau } from "../utils/links";

const callOutContent = (
  <div>
    <CallOutText>
      If you want to learn more about mate wareware please visit:
    </CallOutText>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="http://www.alzheimers.org.nz/getmedia/dbca0bf2-0c1b-403e-b323-4ef973e57be9/Maori_what_is_dementia.pdf.aspx"
    >
      <CallOutExternalLink>What is dementia (in Māori)</CallOutExternalLink>{" "}
      <img src="images/external_link.svg" alt="External link" />
    </a>
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.alzheimers.org.nz/getmedia/9ba1c5ad-1396-4844-b9f3-ba6658a2afcc/ALZ_What_is_Dementia_ENG_PRINT.pdf.aspx"
      >
        <CallOutExternalLink>What is dementia (in English)</CallOutExternalLink>{" "}
        <img src="images/external_link.svg" alt="External link" />
      </a>
    </div>
  </div>
);

const WhatIsDementia = () => {
  return (
    <div>
      <Header
        inputHeight="60vh"
        image="/images/photos/what-is-matewareware.jpg"
        link="/"
        media="image"
      />
      <ContentContainer>
        <h1>What is mate wareware (dementia)?</h1>

        {/* <video controls width="100%" poster="/images/video-poster-naida_01.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/naida_01.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video>

        <video controls width="100%" poster="/images/video-poster-makarena-2021.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/Makarena-2021.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video> */}



        <p>Mate wareware is a term often used in Te Ao Māori for dementia.</p>

        <Bullet text="It is an illness of the brain" />
        <Bullet text="It is NOT a normal part of ageing" />

        <p>
          Mate wareware is a general term that includes many different
          conditions that affect our hinengaro, that is, our memory and
          thinking.
        </p>

        <p>
          Everybody experiences memory or thinking difficulties as they get
          older however mate wareware is more than that. Mate wareware is a
          condition that gets worse over time. However, mate wareware is only
          diagnosed when the symptoms interfere with your ability to function in
          your daily life.
        </p>

        <video controls width="100%" poster="/images/video-poster-dr-oliver-menzies.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/dr_oliver_menzies.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video>

        <video controls width="100%" poster="/images/video-poster-oliver1.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/oliver01.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video>

        <video controls width="100%" poster="/images/video-poster-oliver2.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/oliver02.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video>

        <video controls width="100%" poster="/images/video-poster-oliver3.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/oliver03.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video>

        {/* <ImageFull src="images/placeholder.jpg" />
        <p>Caption text goes here</p> */}

        <video controls width="100%" poster="/images/video-poster-oliver4.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/oliver04.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video>

        <video controls width="100%" poster="/images/video-poster-oliver5.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/oliver05.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video>

        <CallOut callOutContent={callOutContent} />

        <h2>Related content</h2>

        <CardsImagesFlex>
          <CardImage
            title="Types, symptoms and causes of mate wareware"
            text="Many people present with a combination of different types of mate wareware."
            image="/images/photos/types-symptons-causes.jpg"
            link={typesSymptonsCauses}
          />
          <CardImage
            title="Kaitiakitanga: looking after affected whānau"
            text="Listen carefully and know the person’s past. Kōrero with them about their stories."
            image="/images/photos/affected-whanau.jpg"
            link={affectedWhanau}
          />
        </CardsImagesFlex>
      </ContentContainer>
    </div>
  );
};

export default WhatIsDementia;
