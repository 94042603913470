import React from "react";
import CallOut, {
  CallOutExternalLink,
  CallOutText,
} from "../components/CallOut";
import According from "../components/According";
import Bullet from "../components/Bullet";
import CardImage from "../components/CardImage";

// links
import { types, causes } from "../utils/links";

import {
  ContentContainer,
  TopTitle,
  AccordingContainer,
  CardsImagesFlex
} from "../styles/general";

const earlyStage = (
  <>
    <Bullet text="Changes in personality." />
    <Bullet text="Changes in mood." />
    <Bullet text="Changes in behaviour." />
    <Bullet text="Subtle short-term memory problems." />
    <Bullet text="Difficulty with language or communication." />
    <Bullet text="Difficulty with higher order complex thinking such as problem solving, planning and organizing, reading instructions with multi-layered instructions, or attending to more than one task at the same time." />
    <Bullet text="Difficulty in completing normal tasks." />
    <Bullet text="Changes in co-ordination and being aware of your surroundings and your position relative to them." />
    <Bullet text="A lack of feeling, emotion, interest, or concern about what's going on around you." />
    <Bullet text="Struggling to adapt to change." />
  </>
);

const middleStage = (
  <>
    <Bullet text="Problems sleeping and confusing day and night." />
    <Bullet text="Behaving inappropriately in social settings." />
    <Bullet text="Wandering or becoming lost." />
    <Bullet text="Difficulty with how a person processes and interprets information from any of the following senses, i.e. sight, hearing, taste, touch or smell." />
    <Bullet text="Seeing or hearing things that are not real (But not Matakite)." />
    <Bullet text="Increased aggression and irritability." />
    <Bullet text="Inability to recall personal history, address, and phone number." />
  </>
);

const lateStage = (
  <>
    <Bullet text="Difficulty eating and swallowing" />
    <Bullet text="Considerable changes in weight (both loss and gain)" />
    <Bullet text="Incontinence" />
    <Bullet text="Gradual loss of speech" />
    <Bullet text="Restlessness" />
    <Bullet text="Angry outbursts due to confusion" />
    <Bullet text="Increasingly vulnerable to infections, especially pneumonia" />
  </>
);

const callOutContent = (
  <div>
    <CallOutText>
      If you want to know more about the different types of mate wareware,{"  "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.alzheimers.org.nz/"
      >
        <CallOutExternalLink>
          visit the Alzheimer’s NZ website
        </CallOutExternalLink>{" "}
        <img src="images/external_link.svg" alt="External link" />
      </a>
    </CallOutText>
  </div>
);

const Symptoms = () => {
  return (
    <TopTitle>
      <ContentContainer>
        <h1>Common symptoms</h1>
        <p>
          These are common symptoms of mate wareware although not everybody
          experiences all these symptoms. The symptoms displayed in each stage
          can differ from person to person. This is a guideline only to help
          whānau know what to expect and when to expect it.
        </p>

        <AccordingContainer>
          <According
            title="Early stages of mate wareware"
            content={earlyStage}
          />
          <According
            title="Middle stages of mate wareware"
            content={middleStage}
          />
          <According
            title="Late stages of mate wareware"
            content={lateStage}
            borderBottom="1.5px solid #000000"
          />
        </AccordingContainer>

        <CallOut callOutContent={callOutContent} />

        <h2>Related content</h2>

        <CardsImagesFlex>
          <CardImage
            title="Types of mate wareware"
            text="Many people present with a combination of different types of mate wareware."
            image="/images/icon-TypesOf.svg"
            link={types}
          />
          <CardImage
            title="What causes mate wareware?"
            text="Conditions that are connected to changes that take place in the brain of a person suffering from mate wareware. "
            image="/images/icon-Causes.svg"
            link={causes}
          />
        </CardsImagesFlex>

      </ContentContainer>
    </TopTitle>
  );
};

export default Symptoms;
