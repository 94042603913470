import React from "react";
import Header from "../components/Header";
import CardHorizontal from "../components/CardHorizontal";

import { ContentContainer } from "../styles/general";

// import { CallOutExternalLink } from "../components/CallOut";

const contentOne = (
  <>
    <p>
      If your whānau member with dementia says something you don’t agree with,
      let it be. Arguing or saying “no” usually only makes things worse and may
      heighten the level of agitation for them.
    </p>
    <p>
      Knowing about mate wareware and imagining what it would be like to be that
      person with mate wareware can help your understanding of this disease and
      you might be less likely to get hōhā with them. Listen carefully and know
      the person’s past. Kōrero with them about their stories.
    </p>{" "}
  </>
);

const contentTwo = (
  <>
    <p>
      Go slowly and take your time. People with mate wareware slow down and they
      can’t think or act as fast as you and I.
    </p>
  </>
);

const contentThree = (
  <>
    <p>
      People with dementia may blame you for taking, moving or stealing their
      things. They may complain about the way you have prepared their kai. Try
      not to get upset if this happens as they are only upset because of their
      mate wareware. You may need to talk to someone in the whānau or get extra
      support from Dementia services as situations like this can be very hurtful
      and stressful.
    </p>
  </>
);

const contentFour = (
  <>
    <p>
      People with mate wareware like routine. Keep things the same way every
      day. Don’t change anything in a hurry. Make meal times, shower time and
      going to the shop time the same. If people like things done in a certain
      way, stick to that way so they will be less confused and become less hōhā.
    </p>
  </>
);

const contentFive = (
  <>
    <p>
      People with mate wareware can still do many things for themselves. They
      might not be able to do a whole task but can do some parts of it.
      Encourage and help them with this as it is better for them physically,
      emotionally and mentally to keep doing things. We all like to be
      independent and a person with mate wareware should be encouraged to be
      independent whenever possible.
    </p>
  </>
);

const contentSix = (
  <>
    <p>
      The person with mate wareware is still a person with their own thoughts
      and feelings and their own ways of coping. Acknowledge their uniqueness.
    </p>
  </>
);

const contentSeven = (
  <>


    {/* 
    <p>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.youtube.com/watch?v=Wj4nfIOuMww"
      >
        <CallOutExternalLink>
          Video link.
        </CallOutExternalLink>{" "}
        <img src="images/external_link.svg" alt="External link" />
      </a>
    </p> */}

    <p>
      Hearing familiar sounds, sensing familiar emotions, hearing long forgotten
      memories and stories can sometimes help the person with mate wareware.
    </p>
    <p>
      The whānau member with mate wareware often functions better when they are
      on a marae. Their roles and responsibilities are well-learned and they are
      confident to karanga, whaikōrero, karakia, mihi or waiata.
    </p>
    <p>
      Research has shown that some people with mate wareware who grew up on the
      marae and then went away may experience improved functions when they come
      back onto the marae as an older person. Some will speak te reo Māori even
      though they have never spoken it since childhood. The return to the marae
      may stimulate access to long-term memory pathways and information that has
      laid dormant and untapped for decades.
    </p>
    <iframe
      width="100%"
      height="400"
      src="https://www.youtube.com/embed/Wj4nfIOuMww"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Dementia Te Ao Māori"
    >

    </iframe>
    <p>
      TV1 Attitude programme “Dementia Te Ao Māori”. Journey with the Cusack
      whānau who lived with mate wareware. Sadly, Maraea Cusack passed away
    18th September 2020. Moe mai ra e te whaea, moe mai, moe mai. {" "}
    </p>
  </>
);

const AffectedWhanau = () => {
  return (
    <div>
      <Header
        inputHeight="60vh"
        image="/images/photos/affected-whanau.jpg"
        link="/"
        media="image"
      />
      <ContentContainer>
        <h1>Kaitiakitanga: Looking after affected whānau Treasure and her mum Oha</h1>
        <video controls width="100%" poster="/images/video-poster-treasure01.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/treasure01.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video>


        <CardHorizontal
          icon="images/afected-1.svg"
          title="Aroha me whakarongo hoki / Loving and listening"
          content={contentOne}
        />

        <CardHorizontal
          icon="images/afected-2.svg"
          title="Āta Haere / Be patient"
          content={contentTwo}
        />
        <CardHorizontal
          icon="images/afected-3.svg"
          title="Kaua e whakararuraru I te uapare / Try not to get upset about blaming"
          content={contentThree}
        />
        <CardHorizontal
          icon="images/afected-4.svg"
          title="Kia tau te rangimārie / Keep things the same way"
          content={contentFour}
        />
        <CardHorizontal
          icon="images/afected-5.svg"
          title="Awhinatia / Encourage them"
          content={contentFive}
        />
        <CardHorizontal
          icon="images/afected-6.svg"
          title="Whakamana I te tangata / Each person is different"
          content={contentSix}
        />
        <CardHorizontal
          icon="images/afected-7.svg"
          title="Wā whakapau I te marae / Spend time at the marae"
          content={contentSeven}
        />
      </ContentContainer>
    </div>
  );
};

export default AffectedWhanau;
