import React from "react";
import styled from "styled-components";
import {
  fontFamily,
  fontNormal,
  fontBlack,
  fontRegular,
} from "../styles/fonts";

const HeaderContainer = styled.div`
  position: relative;
`;

const VideoContainer = styled.video`
  display: inherit;
  object-fit: cover;
  left: 0;
  height: ${(props) => props.inputHeight || "90vh"};
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -100;
`;

const ImageContainer = styled.img`
  display: inherit;
  object-fit: cover;
  left: 0;
  height: ${(props) => props.inputHeight || "90vh"};
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -100;
`;

const VideoMask = styled.div`
  background-color: black;
  height: ${(props) => props.inputHeight || "90vh"};
  position: absolute;
  opacity: 0.55;
  position: relative;
  width: 100%;
  z-index: -99;
`;

const TaglineContainer = styled.div`
  bottom: 120px;
  color: white;
  left: 20px;
  position: absolute;
  z-index: -98;

  @media only screen and (min-width: 768px) {
    bottom: 142px;
    left: 72px;
  }
`;

const Title = styled.h1`
  color: white;
  font-family: ${fontFamily};
  font-size: 56px;
  font-style: ${fontNormal};
  font-weight: ${fontBlack};
  line-height: 1;

  @media only screen and (min-width: 768px) {
    font-size: 82px;
    width: 600px;
  }
`;

const Text = styled.p`
  color: white;
  font-family: ${fontFamily};
  font-size: 20px;
  font-style: ${fontNormal};
  font-weight: ${fontRegular};
  line-height: 1;
  margin-top: -10px;
  width: 220px;
  line-height: 1;

  @media only screen and (min-width: 768px) {
    margin-top: -30px;
    width: 100%;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
`;


const Header = ({ video, image, title, text, media, inputHeight }) => {
  return (
    <div>
      <HeaderContainer>
        <VideoMask inputHeight={inputHeight}></VideoMask>
        {media === "video" ? (
          <>
            <VideoContainer
              inputHeight={inputHeight}
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={video} type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
            </VideoContainer>
          </>
        ) : (
            <>
              <ImageContainer inputHeight={inputHeight} src={image} alt={title} />
            </>
          )}

        <TaglineContainer>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </TaglineContainer>

        {media === "video" &&
          <ArrowContainer>
            <img src="/images/arrow-header.svg" alt="Header arrow" />
          </ArrowContainer>
        }

      </HeaderContainer>
    </div>
  );
};

export default Header;
