import React from "react";
// import { Link } from "react-router-dom";
import styled from "styled-components";
import { linkColor } from "../styles/colors";
import { fontFamily, fontNormal, fontBold } from "../styles/fonts";

const CallOutContainer = styled.div`
  background-color: #f0f0f1;
  border-left: 5px solid #5b3fa5;
  margin: 50px 0;
  padding: 20px;
`;

export const CallOutText = styled.p`
  font-family: ${fontFamily};
  font-size: 20px;
  font-style: ${fontNormal};
  font-weight: ${fontBold};
`;

export const CallOutExternalLink = styled.span`
  color: ${linkColor};
  font-family: ${fontFamily};
  font-size: 20px;
  font-style: ${fontNormal};
  font-weight: ${fontBold};
  text-decoration: underline;
`;

// export const CallOutLink = ({ textLink, link = "/" }) => {
//   return (
//     <Link to={link}>
//       <CallOutExternalLink>{textLink}</CallOutExternalLink>{" "}
//       <img src="images/external_link.svg" alt="External link" />
//     </Link>
//   );
// };

// export const CallOutText = ({ text }) => {
//   return <CallOutText>{text}</CallOutText>;
// };

const CallOut = ({ callOutContent }) => {
  return <CallOutContainer>{callOutContent}</CallOutContainer>;
};

export default CallOut;
