import React from "react";
import styled from "styled-components";
import { ContentContainer, TopTitle } from "../styles/general";
import Quote from "../components/Quote";
import { fontBold } from "../styles/fonts";

const Title = styled.h1`
  font-size: 32px;
`;

const Name = styled.p`
  font-weight: ${fontBold};
  margin-bottom: -20px;
`;

const NamesContainer = styled.div`
  margin: 50px 0;
`;

const Acknowledgements = () => {
  return (
    <TopTitle>
      <ContentContainer>
        <Title>Acknowledgements</Title>
        <Quote
          text="He aha te mea nui o te Ao maku e ki atu. He tangata he tangata he tangata. 
What is the most important thing in the world? It is people, people, people."
        />
        <p>
          There are many people who graciously gave up their time to contribute
          to the making of this app. Ngā mihi aroha ki a koutou.
        </p>

        <NamesContainer>
          <Name>Dr Makarena Dudley</Name>
          <p>University of Auckland (UoA)/Brain Research New Zealand (BRNZ)</p>

          <Name>Mr Marcus King</Name>
          <p>MedTech/Callaghan Innovation</p>

          <Name>Dr Susan Yates</Name>
          <p>UoA/Centre for Brain Research (CBR)</p>

          <Name>Dr Alex Merkin</Name>
          <p>Auckland University of Technology (AUT)</p>

          <Name>Dr Sarah Cullum</Name>
          <p>UoA/BRNZ</p>

          <Name>Dr Sharon Olsen</Name>
          <p>AUT</p>

          <Name>Mr Hohepa Spooner</Name>
          <p>AUT</p>

          <Name>Dr Rodriguez-Ramirez</Name>
          <p>Victoria University Wellington</p>

          <Name>Dame Rangimārie Naida Glavish</Name>
          <p>
            Waitematā District Health Board (WDHB)/Auckland District Health
            Board (ADHB)
          </p>

          <Name>Matua Piripi Daniels</Name>
          <p>UoA/BRNZ</p>

          <Name>Dr Waiora Port </Name>
          <p>UoA/BRNZ</p>

          <Name>Ms Ngāwairongoa Herewini</Name>
          <p>ABI Rehabilitation</p>

          <Name>Whaea Oha Nepia</Name>
          <p>(formerly Thomas, nee Reihana)</p>

          <Name>Ms Treasure Thomas-Egglestone</Name>
          <p><br /></p>

          <Name>Dr Oliver Menzies</Name>
          <p>ADHB</p>

          <Name>Ms Bobby Nepia</Name>
          <p>Dementia Auckland</p>

          <Name>Ms Beryl Kaa</Name>
          <p>CMDHB</p>

          <Name>Ms Hollie Howard</Name>
          <p>AUT</p>

          <Name>Ms Cherry Reihana</Name>
          <p>UoA</p>

          <Name>Ms Tori Diamond</Name>
          <p>UoA</p>

          <Name>Ms Zohreh Shirazi</Name>
          <p>AUT</p>

          <Name>Ms Sophia Spivak</Name>
          <p>AUT</p>

          <Name>Dr Rodrigo Ferrari</Name>
          <p>AUT</p>

        </NamesContainer>
      </ContentContainer>
    </TopTitle >
  );
};

export default Acknowledgements;
