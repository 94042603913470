import React, { useRef, useState } from "react";
import { Link } from 'react-router-dom'
import styled from "styled-components";
import ReactAudioPlayer from "react-audio-player";

const SplashContainer = styled.div`
  background-color: white;
  height: 100%;
  position: fixed;
  text-align: center;
  width: 100%;
  top: 0;
  z-index: 999;
`;

const LogoSplash = styled.div`
  margin-top: 70px;

  @media only screen and (min-width: 768px) {
    margin-top: 170px;
  }
`;

const Button = styled.button`
  background-color: white;
  border: 1px solid black;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 10px;
  width: 200px;
`;

const ButtonSkip = styled.button`
  background-color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  width: 200px;
`;


const Splash = ({ skip }) => {
  const player = useRef();
  const [isPlaying, serIsPlaying] = useState(false)

  const audioPlay = () => {
    player.current.audioEl.current.play()
    serIsPlaying(true)
  };

  const audioPause = () => {
    player.current.audioEl.current.pause()
    serIsPlaying(false)
  };

  return (
    <SplashContainer>
      <LogoSplash>
        <img src="images/splash-logo.svg" alt="Mate wareware - dementia" />
      </LogoSplash>
      <div>

        {isPlaying ? <Button onClick={audioPause}>Karakia wiremu / Pause</Button> : <Button onClick={audioPlay}>Karakia wiremu / Play</Button>}

      </div>
      <div>
        <Link to="/home">
          <ButtonSkip>Haere tonu / Continue</ButtonSkip>
        </Link>
      </div>

      <ReactAudioPlayer
        src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/karakia_wiremu.mp3"
        // autoPlay={true}
        onEnded={skip}
        ref={player}
      // onPause={() => console.log("pause")}
      />
    </SplashContainer >
  );
};

export default Splash;
