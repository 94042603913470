import React, { useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
// import { useSpring, animated } from "react-spring";
import { firstColor, secondColor } from "../styles/colors";
import { fontFamily, fontNormal, fontBold, fontLight } from "../styles/fonts";

// links
import {
  whatIsDementia,
  maoriUnderstanding,
  typesSymptonsCauses,
  whatCanIDo,
  affectedWhanau,
  whatsWrong,
  resources,
  acknowledgements,
} from "../utils/links";

const TopBarContainer = styled.div`
  background-color: ${secondColor};
  color: white;
  height: 64px;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 100;
`;

const TopBarContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Logo = styled.div`
  margin-top: 22px;
  margin-left: 20px;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    margin-left: 48px;
  }
`;

const LogoCenterDesktop = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    cursor: pointer;
    margin-top: 22px;
    display: inherit;
  }
`;

const LogoCenterMobile = styled.div`
  cursor: pointer;
  margin-left: 20px;
  margin-top: 23px;
  display: inherit;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const MenuIcon = styled.img`
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    margin-right: 20px;
  }
`;

const MenuContainer = styled.div`
  background-color: ${firstColor};
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 1;

  @media only screen and (min-width: 768px) {
  }
`;

const MenuTextContainer = styled.div`
  margin: 50px 38px;

  @media only screen and (min-width: 768px) {
    padding-right: 20px;
    text-align: right;
  }
`;

const MenuText = styled.p`
  color: white;
  font-family: ${fontFamily};
  font-size: 20px;
  font-style: ${fontNormal};
  font-weight: ${fontBold};
`;

const MenuTextLight = styled.p`
  color: white;
  font-family: ${fontFamily};
  font-size: 20px;
  font-style: ${fontNormal};
  font-weight: ${fontLight};
`;

const SubMenu = styled.div`
  margin-top: 70px;
`;

const TopBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  // const fade = useSpring({ opacity: 1, from: { opacity: 0 } });

  let location = useLocation();
  let path = location.pathname;

  let history = useHistory();

  const handleGoBack = () => {
    history.goBack();
    setShowMenu(false);
  };

  return (
    <TopBarContainer>
      <TopBarContent>
        {path === "/" ? (
          <Logo>
            <Link to="/">
              <img
                onClick={() => setShowMenu(false)}
                src="images/logo-top-bar-fix.svg"
                alt="Logo"
              />
            </Link>
          </Logo>
        ) : (
            <Logo>
              <Link to="/">
                <img
                  onClick={() => handleGoBack()}
                  src="images/back-bt.svg"
                  alt="Logo"
                />
              </Link>
            </Logo>
          )}

        {path !== "/" && (
          <div>
            <LogoCenterDesktop>
              <Link to="/">
                <img
                  onClick={() => setShowMenu(false)}
                  src="images/logo-top-bar.svg"
                  alt="Logo"
                />
              </Link>
            </LogoCenterDesktop>
            <LogoCenterMobile>
              <Link to="/">
                <img
                  onClick={() => setShowMenu(false)}
                  src="images/logo-top-bar-brain.svg"
                  alt="Logo"
                />
              </Link>
            </LogoCenterMobile>
          </div>
        )}

        {showMenu === false ? (
          <MenuIcon
            onClick={() => setShowMenu(!showMenu)}
            src="images/hamburger-top-bar.svg"
            alt="Open Menu"
          />
        ) : (
            <MenuIcon
              onClick={() => setShowMenu(!showMenu)}
              src="images/close-top-bar.svg"
              alt="Close Menu"
            />
          )}
      </TopBarContent>
      {showMenu === true && (
        <div>
          <MenuContainer>
            <MenuTextContainer>

              <Link to="/">
                <MenuText onClick={() => setShowMenu(!showMenu)}>
                  Tīmatanga Kōrero
                </MenuText>
              </Link>

              <Link to={whatIsDementia}>
                <MenuText onClick={() => setShowMenu(!showMenu)}>
                  What is mate wareware?
                </MenuText>
              </Link>

              <Link to={maoriUnderstanding}>
                <MenuText onClick={() => setShowMenu(!showMenu)}>
                  Māori understanding
                </MenuText>
              </Link>

              <Link to={typesSymptonsCauses}>
                <MenuText onClick={() => setShowMenu(!showMenu)}>
                  Types, symptoms & causes
                </MenuText>
              </Link>

              <Link to={whatCanIDo}>
                <MenuText onClick={() => setShowMenu(!showMenu)}>
                  What can I do?
                </MenuText>
              </Link>

              <Link to={affectedWhanau}>
                <MenuText onClick={() => setShowMenu(!showMenu)}>
                  Affected whānau
                </MenuText>
              </Link>

              <Link to={whatsWrong}>
                <MenuText onClick={() => setShowMenu(!showMenu)}>
                  What’s wrong with our kuia?
                </MenuText>
              </Link>

              <SubMenu>
                <Link to={resources}>
                  <MenuTextLight onClick={() => setShowMenu(!showMenu)}>
                    Resources
                  </MenuTextLight>
                </Link>

                <Link to={acknowledgements}>
                  <MenuTextLight onClick={() => setShowMenu(!showMenu)}>
                    Acknowledgements
                  </MenuTextLight>
                </Link>
              </SubMenu>
            </MenuTextContainer>
          </MenuContainer>
        </div>
      )}
    </TopBarContainer>
  );
};

export default TopBar;
