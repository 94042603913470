import React from "react";
import styled from "styled-components";
import Bullet from "../components/Bullet";
import { linkColor } from "../styles/colors";

import { ContentContainer, TopTitle } from "../styles/general";
import { fontBold } from "../styles/fonts";

const LinkBullet = styled.span`
  color: ${linkColor};
  text-decoration: underline;
  font-weight: ${fontBold};

  font-family: "questa-sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: 1.5;
`;

const externalLink = (
  <span>
    {"  "}
    <img src="images/external_link.svg" alt="External link" />
  </span>
);

const textOne = (
  <>
    <a
      target="_blank"
      href="https://www.alzheimers.org.nz/getattachment/Information-and-support/Information/Booklets-and-fact-sheets/About-dementia-2019-update.pdf/"
      rel="noopener noreferrer"
    >
      <LinkBullet>About dementia</LinkBullet>
      {externalLink}
    </a>{" "}
    - A guide for people diagnosed with dementia.
  </>
);

const textTwo = (
  <>
    <a
      target="_blank"
      href="http://www.carers.net.nz/wp-content/uploads/2017/05/GuideforCarers2016.pdf"
      rel="noopener noreferrer"
    >
      <LinkBullet>A Guide for Carers</LinkBullet>
      {externalLink}
    </a>{" "}
    - Practical help for supporting family or friends.
  </>
);

const textThree = (
  <>
    <a
      target="_blank"
      href="http://www.alzheimers.org.nz"
      rel="noopener noreferrer"
    >
      <LinkBullet>Alzheimers New Zealand</LinkBullet>
      {externalLink}
    </a>{" "}
  </>
);

const textFour = (
  <>
    <a target="_blank" href="http://www.dementia.nz" rel="noopener noreferrer">
      <LinkBullet>Dementia New Zealand</LinkBullet>
      {externalLink}
    </a>{" "}
  </>
);

const textFive = (
  <>
    <a
      target="_blank"
      href="https://www.yodat.org.nz/"
      rel="noopener noreferrer"
    >
      <LinkBullet>Younger Onset Dementia Aotearoa Trust</LinkBullet>
      {externalLink}
    </a>{" "}
  </>
);

const textSix = (
  <>
    <a target="_blank" href="https://nzdementia.org" rel="noopener noreferrer">
      <LinkBullet>New Zealand Dementia Cooperative</LinkBullet>
      {externalLink}
    </a>{" "}
  </>
);

const textSeven = (
  <>
    <a
      target="_blank"
      href="https://www.health.govt.nz/our-work/life-stages/health-older-people/needs-assessment"
      rel="noopener noreferrer"
    >
      <LinkBullet>Needs Assessment & Service Co-ordination (NASC)</LinkBullet>
      {externalLink}
    </a>{" "}
  </>
);

const textEight = (
  <>
    <a
      target="_blank"
      href="https://www.ageconcern.org.nz"
      rel="noopener noreferrer"
    >
      <LinkBullet>Age Concern </LinkBullet>
      {externalLink}
    </a>{" "}
    - Dedicated to people over 65, their friends, and whānau.
  </>
);

const textNine = (
  <>
    <a
      target="_blank"
      href="https://www.seniorline.org.nz"
      rel="noopener noreferrer"
    >
      <LinkBullet>Senior Line </LinkBullet>
      {externalLink}
    </a>{" "}
    - An information service to navigate the health system.
  </>
);

const textTen = (
  <>
    <a
      target="_blank"
      href="https://www.carers.net.nz"
      rel="noopener noreferrer"
    >
      <LinkBullet>Carers New Zealand </LinkBullet>
      {externalLink}
    </a>{" "}
    - Information, advice, support for carers.
  </>
);

const textEleven = (
  <>
    <a target="_blank" href="https://www.cab.org.nz/" rel="noopener noreferrer">
      <LinkBullet>Citizens Advice Bureau</LinkBullet>
      {externalLink}
    </a>{" "}
    - Free, confidential, independent information and advice for anyone.
  </>
);

const Resources = () => {
  return (
    <TopTitle>
      <ContentContainer>
        <h1>Resources</h1>
        <p>
          The following as provide more information and support for living well
          with dementia:
        </p>

        <Bullet text={textOne} />
        <Bullet text={textTwo} />
        <Bullet text={textThree} />
        <Bullet text={textFour} />
        <Bullet text={textFive} />
        <Bullet text={textSix} />
        <Bullet text={textSeven} />
        <Bullet text={textEight} />
        <Bullet text={textNine} />
        <Bullet text={textTen} />
        <Bullet text={textEleven} />
      </ContentContainer>
    </TopTitle>
  );
};

export default Resources;
