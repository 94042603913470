import React from "react";
import According from "../components/According";
import {
  ContentContainer,
  TopTitle,
  AccordingContainer,
  CardsImagesFlex
} from "../styles/general";

import CardImage from "../components/CardImage";

// links
import { symptoms, types } from "../utils/links";

// import { whatCanIDo } from "../utils/links";

const contentAge = (
  <p>
    Mate wareware can affect anyone but mostly it is our kuia and kaumātua who
    develop mate wareware. However, this does not mean that all older people
    will develop mate wareware.
  </p>
);

const contentWhakappa = (
  <p>
    Most types of mate wareware are not passed down through the whānau. If your
    mum or dad or other whānau member has mate wareware this does not mean that
    you will get it. Only a small percentage of mate wareware types are strongly
    linked to whakapapa. However, other health conditions such as high blood
    pressure or diabetes may be passed down through your whānau and may increase
    your risk of mate wareware.
  </p>
);

const contentHeart = (
  <p>
    Heart disease includes conditions that affect the heart and blood vessels.
    Common heart diseases are high cholesterol, high blood pressure, diabetes,
    obesity, and stroke. These conditions can affect the blood supply to your
    brain. It is therefore important to manage these conditions to keep your
    heart and brain as healthy as possible.
  </p>
);

const contentHead = (
  <p>
    Having a history of repeated head injuries may increase your risk of
    developing mate wareware.{" "}
  </p>
);
const contentDepression = (
  <p>
    Depression is a common mental health problem. Research is unclear as to
    whether depression causes mate wareware or if changes in the brain due to
    mate wareware cause depression. If you are feeling low in mood it is
    important that you seek help. Talking with others may help, so kōrero with
    whānau, friends or your doctor.
  </p>
);
const contentSleep = (
  <p>
    Sleep problems are experienced by most people at some point in their life.
    People who experience ongoing sleep problems may be at increased risk of
    mate wareware. If you are experiencing long term sleep problems, it is
    important that you seek help from your doctor.
  </p>
);
const contentLife = (
  <p>
    Life events that cause long term stress may negatively affect the brain and
    contribute to developing mate wareware. If you are having difficulty or
    feeling overwhelmed it is important to seek help to reduce your stress
    levels.
  </p>
);

const Causes = () => {
  return (
    <TopTitle>
      <ContentContainer>
        <h1>What causes mate wareware?</h1>
        {/* <Link to={whatCanIDo}>
          <SimpleLink>If I’m concerned, what can I do?</SimpleLink>
        </Link> */}
        <p>
          It is not actually known what causes mate wareware but there are many
          conditions that are connected to changes that take place in the brain
          of a person suffering from mate wareware.
        </p>

        <AccordingContainer>
          <According title="Age" content={contentAge} />
          <According title="Whakapapa/genetics" content={contentWhakappa} />
          <According
            title="Heart (Cardiovascular) Disease"
            content={contentHeart}
          />
          <According title="Head injuries" content={contentHead} />
          <According title="Depression" content={contentDepression} />
          <According title="Sleep disorders" content={contentSleep} />
          <According
            title="Life stress"
            content={contentLife}
            borderBottom="1.5px solid #000000"
          />
        </AccordingContainer>

        <h2>Related content</h2>

        <CardsImagesFlex>
          <CardImage
            title="Common symtoms"
            text="There are common symptoms of mate wareware although not everybody experiences all these symptoms."
            image="/images/icon-CommonSymptoms.svg"
            link={symptoms}
          />
          <CardImage
            title="Types of mate wareware"
            text="Many people present with a combination of diferent types of mate wareware"
            image="/images/icon-TypesOf.svg"
            link={types}
          />
        </CardsImagesFlex>
      </ContentContainer>
    </TopTitle>
  );
};

export default Causes;
