import React from "react";
import CardImage from "../components/CardImage";
import Bullet from "../components/Bullet";
import CallOut, {
  CallOutExternalLink,
  CallOutText,
} from "../components/CallOut";

import { ContentContainer, CardsImagesFlex, TopTitle } from "../styles/general";

// links
import { symptoms, causes } from "../utils/links";

const callOutContent = (
  <div>
    <CallOutText>
      If you want to know more about the different types of mate wareware,{"  "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.alzheimers.org.nz/information-and-support/information/what-is-dementia/he-aha-tenei-mea-te-mate-wareware-what-is-demen"
      >
        <CallOutExternalLink>
          visit the Alzheimer’s NZ website
        </CallOutExternalLink>{" "}
        <img src="images/external_link.svg" alt="External link" />
      </a>
    </CallOutText>
  </div>
);

const Types = () => {
  return (
    <TopTitle>
      <ContentContainer>
        <h1>Types of mate wareware</h1>
        <p>
          Many people present with a combination of different types of mate
          wareware.
        </p>
        <p>Common types of mate wareware include:</p>
        <Bullet text="Alzheimers Disease (the most common)" />
        <Bullet text="Vascular Dementia" />
        <Bullet text="Lewy Body Dementia" />
        <Bullet text="Frontotemporal Dementia" />
        <Bullet text="Alcohol Related Dementia" />
        <Bullet text="Mixed Dementia" />
        <CallOut callOutContent={callOutContent} />
        <h2>Related content</h2>
        <CardsImagesFlex>
          <CardImage
            title="Common symptoms"
            text="These are common symptoms of mate wareware altough not everybody experiences all these symptoms."
            image="/images/icon-CommonSymptoms.svg"
            link={symptoms}
          />
          <CardImage
            title="What causes mate wareware?"
            text="Conditions that are connected to changes that take place in the brain of a person suffering from mate wareware. "
            image="/images/icon-Causes.svg"
            link={causes}
          />
        </CardsImagesFlex>
      </ContentContainer>
    </TopTitle>
  );
};

export default Types;
