import React from "react";
import styled from "styled-components";

const QuoteContainer = styled.div`
  background-color: #f3ecf5;
  border-left: 5px solid #66127c;
  margin: 50px 0;
  padding: 20px;

  @media only screen and (min-width: 768px) {
  }
`;

const Text = styled.p`
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
`;

const Quote = ({ text }) => {
  return (
    <QuoteContainer>
      <img src="images/quotes.svg" alt="Quote symbol" />
      <Text>{text}</Text>
    </QuoteContainer>
  );
};

export default Quote;
