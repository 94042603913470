import React from "react";
import Header from "../components/Header";
import { ContentContainer } from "../styles/general";


const Home = () => {
  return (
    <div>
      <Header
        video="/videos/home-background.mp4"
        title="Mate wareware
            (dementia)"
        text="Understanding mate wareware (dementia) from a Māori perspective"
        link="/"
        media="video"
      />
      <ContentContainer>

        <h1>Tīmatanga Kōrero</h1>

        <p>Kaumātua are living longer than ever before however unfortunately,
        we are also seeing more kaumātua being diagnosed with mate wareware (dementia).
        The good news is that If we live a healthy life we are able to reduce the risk
        of getting mate wareware. This app provides information for whānau on how to
        manage mate wareware as well as information about what actions and behaviours
        can be adopted across the life span to help prevent this disease.
      </p>

        <video controls width="100%" poster="/images/video-poster-naida_01.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/naida_01.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video>

        <video controls width="100%" poster="/images/video-poster-dr-waiora-port.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/dr_waiora_port-small.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video>

        <video controls width="100%" poster="/images/video-poster-makarena-2021.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/Makarena-2021.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video>

        <video controls width="100%" poster="/images/video-poster-marcus-king.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/marcus-king.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video>


      </ContentContainer>

    </div>
  );
};

export default Home;
