// Questa Sans

//font-family: questa-sans, sans-serif;
export const fontFamily = '"Open Sans", sans-serif';

// font-style: normal;
export const fontNormal = "normal";

// font-weight: ;
export const fontLight = "300";
export const fontRegular = "400";
export const fontBold = "700";
export const fontBlack = "900";
