/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:290e3603-3936-48db-92f6-62f3b06f5941",
    "aws_cognito_region": "ap-southeast-2",
    "oauth": {},
    "aws_mobile_analytics_app_id": "a06517767315406aadbccfa9937e3402",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
