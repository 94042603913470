import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  fontFamily,
  fontNormal,
  fontBlack,
  fontRegular,
} from "../styles/fonts";
import { firstColor } from "../styles/colors";

const CardContainer = styled.div`
  border: 0.5px solid #e6e6e6;
  margin: 20px 0;
  text-align: center;
  width: 280px;
  height: 460px;

  @media only screen and (min-width: 768px) {
    margin: 20px 20px 20px 0;
  }
`;

const Image = styled.img`
  display: inherit;
  object-fit: cover;
  left: 0;
  height: 152px;
  width: 100%;
`;

// const Image = styled.img`
//   width: 100%;
// `;

const TextContainer = styled.div`
  padding: 0 20px 20px 20px;
`;

const Title = styled.p`
  color: ${firstColor};
  font-family: ${fontFamily};
  font-size: 26px;
  font-style: ${fontNormal};
  font-weight: ${fontBlack};
  line-height: 1;
  text-align: left;
`;

const Text = styled.p`
  font-family: ${fontFamily};
  font-size: 20px;
  font-style: ${fontNormal};
  font-weight: ${fontRegular};
  line-height: 1.4;
  text-align: left;
`;

const CardImage = ({ text, title, image, link }) => {
  return (
    <CardContainer>
      <Link to={link}>
        <Image src={image} alt={title} />
        <TextContainer>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </TextContainer>
      </Link>
    </CardContainer>
  );
};

export default CardImage;
