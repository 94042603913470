import React from "react";

import Header from "../components/Header";
import Card from "../components/Card";

import { ContentContainer, CardsFlex } from "../styles/general";
import { types, symptoms, causes } from "../utils/links";

const TypesSymptonsCauses = () => {
  return (
    <div>
      <Header
        inputHeight="60vh"
        image="/images/photos/types-symptons-causes.jpg"
        media="image"
      />
      <ContentContainer>
        <h1>Types, symptoms and causes of mate wareware</h1>
        {/* <video controls width="100%" poster="/images/video-poster-makarena-2021.jpg">
          <source src="https://dementia-videos.s3-ap-southeast-2.amazonaws.com/Makarena-2021.mp4" type="video/mp4" /> Your browser does not
              support HTML5 video.{" "}
        </video> */}
      </ContentContainer>

      <CardsFlex>
        <Card
          text="Types of mate wareware"
          icon="images/icon-TypesOf.svg"
          alt="Types of mate wareware"
          link={types}
        />
        <Card
          text="Common symptoms of mate wareware"
          icon="images/icon-CommonSymptoms.svg"
          alt="Common symptoms of mate wareware"
          link={symptoms}
        />
        <Card
          text="Causes of mate wareware"
          icon="images/icon-Causes.svg"
          alt="Causes of matewareware"
          link={causes}
        />
        <Card />
      </CardsFlex>
    </div>
  );
};

export default TypesSymptonsCauses;
